<template>
  <v-card>
    <v-card-title>
      {{ formTitle }}
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.sprint_name"
            :error-messages="errors.sprint_name"
            :label="formMixin_getRequiredFieldLabel($t('general.title'))"
            @input="formMixin_clearErrors('sprint_name')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-model="item.status"
            :items="projectSprintStatuses"
            :error-messages="errors.status"
            :label="$t('general.status')"
            @change="
              formMixin_clearErrors('status');
              onStatusChange($event);
            "
          />
        </v-col>
      </v-row>

      <v-row v-if="item.status === 'closed' && otherUnclosedSprints.length" dense>
        <v-col cols="12" sm="6">
          <v-select
            v-model="item.sprintIdForLeftoverUserStories"
            :items="otherUnclosedSprints"
            :label="$t('projects.labels.sprint_for_unfinished_stories')"
            item-text="sprint_name"
            item-value="id"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <BaseDatepickerInput
            v-model="item.starts_at"
            :error-messages="errors.starts_at"
            :label="$t('general.date_and_time.start_date')"
            @blur="formMixin_clearErrors('starts_at')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <BaseDatepickerInput
            v-model="item.deadline"
            :error-messages="errors.deadline"
            :label="$t('general.date_and_time.end_date')"
            @blur="formMixin_clearErrors('deadline')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-model="item.contract_status"
            :items="projectSprintContractStatusTypes"
            :error-messages="errors.contract_status"
            :label="$t('projects.labels.agreement_status')"
            clearable
            @change="formMixin_clearErrors('contract_status')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.estimated_hours"
            :error-messages="errors.estimated_hours"
            :label="$t('projects.labels.estimated_hours')"
            @input="formMixin_clearErrors('estimated_hours')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.hours_spent_on_planning"
            :error-messages="errors.hours_spent_on_planning"
            :label="$t('projects.labels.planned_hours')"
            @input="formMixin_clearErrors('hours_spent_on_planning')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.hour_rate"
            :error-messages="errors.hour_rate"
            :label="$t('projects.labels.hour_rate')"
            @input="formMixin_clearErrors('hour_rate')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-model="item.advance_payment_status"
            :items="projectSprintContractStatusTypes"
            :error-messages="errors.advance_payment_status"
            :label="$t('projects.labels.advance_payment_status')"
            clearable
            @change="formMixin_clearErrors('advance_payment_status')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-model="item.final_payment_status"
            :items="projectSprintContractStatusTypes"
            :error-messages="errors.final_payment_status"
            :label="$t('projects.labels.final_payment_status')"
            clearable
            @change="formMixin_clearErrors('final_payment_status')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.waiting_for"
            :error-messages="errors.waiting_for"
            :label="$t('projects.labels.waiting')"
            @input="formMixin_clearErrors('waiting_for')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.suspended_comment"
            :error-messages="errors.suspended_comment"
            :label="$t('projects.labels.suspended_comment')"
            @input="formMixin_clearErrors('suspended_comment')"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <span v-if="!item.id" class="text-subtitle-2 ml-3">
        * {{ $t('general.must_be_filled') }}
      </span>

      <v-spacer />

      <v-btn text @click="$emit('cancel')">
        {{ $t('general.controls.cancel') }}
      </v-btn>

      <v-btn
        :disabled="disabled"
        :loading="disabled"
        color="primary"
        text
        @click="$emit('save', item)"
      >
        {{ $t('general.controls.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import { mapGetters, mapState } from 'vuex';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';

export default {
  name: 'ProjectSprintForm',
  components: { BaseDatepickerInput },
  mixins: [formMixin],

  props: {
    projectSprint: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
    };
  },

  computed: {
    ...mapState('projectSprints', ['projectSprints']),
    ...mapGetters('projectSprints', ['projectSprintStatuses', 'projectSprintContractStatusTypes']),

    formTitle() {
      return this.$t(
        `projects.modal_titles.${this.projectSprint.id ? 'edit_sprint' : 'new_sprint'}`
      );
    },

    otherUnclosedSprints() {
      return this.projectSprints.filter(
        (s) => s.id !== this.projectSprint.id && s.status !== 'closed'
      );
    },
  },

  created() {
    this.item = clone(this.projectSprint);
  },

  methods: {
    onStatusChange(newStatus) {
      if (newStatus === 'closed' && this.otherUnclosedSprints.length) {
        this.item.sprintIdForLeftoverUserStories = this.otherUnclosedSprints[0].id;
      }
    },
  },
};
</script>
