<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <ProjectSprintForm
      :disabled="
        loading[`put:api/project-sprints/${selectedProjectSprint.id}`] ||
        loading['post:api/project-user-stories-split'] ||
        loading[`get:api/clients/${selectedProject.client_id}`]
      "
      :errors="projectSprintValidationErrors"
      :project-sprint="selectedProjectSprint"
      @clear:errors="CLEAR_PROJECT_SPRINT_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import store from '@/store';
import ProjectSprintForm from '@/components/forms/ProjectSprintForm';
import { addDays, format } from 'date-fns';
import contactService from '@/api/contact-service';
import { getSprintUserStorySummary } from '@/util/sprint-summary';

export default {
  name: 'EditProjectSprint',

  components: { ProjectSprintForm },

  computed: {
    ...mapState('projectSprints', ['selectedProjectSprint', 'projectSprintValidationErrors']),
    ...mapState('projects', ['selectedProject']),
    ...mapState('projectUserStories', ['projectUserStoriesBySprint']),
    ...mapGetters(['loading']),

    sprintPrice() {
      const userStories = this.projectUserStoriesBySprint[this.selectedProjectSprint.id];
      if (!userStories?.length) {
        return 0;
      }
      return userStories.reduce((totalPrice, userStory) => totalPrice + +userStory.price, 0);
    },
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('projectSprints/editProjectSprint', +to.params.projectSprintId)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'project', params: { projectId: to.params.projectId } });
      });
  },

  methods: {
    ...mapActions('projectSprints', ['updateProjectSprint']),
    ...mapMutations('projectSprints', ['CLEAR_PROJECT_SPRINT_VALIDATION_ERRORS']),
    ...mapMutations('invoices', ['SET_NEW_INVOICE', 'SET_INVOICE_FOR_SENDING']),

    onSave(sprint) {
      const currentSprintStatus = this.selectedProjectSprint.status;

      this.updateProjectSprint(sprint).then((updatedSprint) => {
        if (currentSprintStatus !== 'closed' && updatedSprint.status === 'closed') {
          this.onSprintClose(updatedSprint);
        } else {
          this.goBack();
        }
      });
    },

    async onSprintClose(closedSprint) {
      let client = null;
      if (this.selectedProject.client_id) {
        const { data } = await contactService.getById(this.selectedProject.client_id);
        client = data;
      }

      this.SET_NEW_INVOICE({
        client,
        client_id: client?.id,
        signed_date: format(new Date(), 'yyyy-MM-dd'),
        due_date: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
        vat_percentage: 21,
        items: [
          {
            title: this.$t('projects.programming_services_by_agreement'),
            quantity: '1',
            unit: this.$t('general.unit'),
            unit_price: this.sprintPrice,
          },
        ],
      });

      const emails = [];
      const email = client?.email_for_invoices || client?.email;
      if (email) {
        emails.push(email);
      }

      this.SET_INVOICE_FOR_SENDING({
        emails,
        comment: getSprintUserStorySummary(closedSprint),
        project: this.selectedProject,
        project_id: this.selectedProject.id,
        sprints: [closedSprint],
      });

      await this.$router.push({ name: 'project_createInvoice' });
    },

    goBack() {
      this.$router.push({ name: 'project' });
    },
  },
};
</script>
